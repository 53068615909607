.about {
  height: 1700px;
  background-color: #61a5c2;
}

.top-about {
  position: relative;
}

.bg-image {
  opacity: 0.4;
  height: 483px;
  width: 100%;
  background-size: cover;
}

.bg-img-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 130px;
  margin: 0 450px;
  color: white;
}

.bg-img-title h1 {
  margin: 0px 0px;
}

.bottom-about {
  height: 794px;
}

.vision-wrap {
  display: flex;
  flex-direction: row;
}

.visionTitle {
  position: absolute;
  background-color: rgb(255, 255, 255);
  border-radius: 170px 0 0 170px;
  width: 900px;
  height: 286px;
  left: 451px;
  top: 653px;
}

.vision-wrap h1 {
  position: absolute;
  width: 200px;
  height: 38px;
  left: 288px;
  top: 780px;
  color: white;
}

.visionTitle p {
  margin: 113px 188px 110px 173px;
  width: 607px;
  font-size: 16px;
  font-weight: bold;
  color: #61a5c2;
  text-align: center;
}

.mission-wrap {
  display: flex;
  flex-direction: row;
}

.missionTitle {
  position: absolute;
  background-color: white;
  /* padding: 87px; */
  border-radius: 0 170px 170px 0;
  width: 900px;
  height: 286px;
  left: 0px;
  top: 1001px;
}

.mission-wrap h1 {
  position: absolute;
  width: 200px;
  height: 38px;
  left: 928px;
  top: 1110px;
  color: white;
}

.missionTitle p {
  width: 607px;
  margin-left: 0;
  margin: 42px 188px 42px 152px;
  font-size: 16px;
  font-weight: bold;
  color: #61a5c2;
  text-align: center;
}

.ourteam-wrapper {
  text-align: center;
  color: white;
}

.ourteam-wrapper h1 {
  border-bottom: white solid 5px;
  margin: 0 150px;
}
.ourteam-wrapper li {
  list-style: none;
  margin: 10px 0px;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (min-width: 391px) and (max-width: 414px) {
  .about {
    height: max-content;
    width: 100%;
    background-color: #61a5c2;
  }

  .bottom-about {
    height: 670px;
  }

  .bg-image {
    opacity: 0.4;
    height: 250px;
    width: 100%;
    background-size: cover;
  }

  .logo-image {
    width: 200px;
    height: 107px;
  }

  .bg-img-title {
    top: 10px;
    margin: 50px 0px;
    width: 414px;
  }

  .bg-img-title h1 {
    margin: 0px 0px;
    font-size: 20px;
  }

  .bottom-about {
    width: 100%;
  }

  .vision-wrap {
    width: 100px;
  }

  .vision-wrap h1 {
    position: absolute;
    width: 200px;
    height: 38px;
    left: 0px;
    top: 440px;
    margin: 0 107px;
    color: white;
    text-align: center;
  }

  .visionTitle {
    border-radius: 15px;
    margin: 0px 25px;
    width: 364px;
    height: 200px;
    left: 0px;
    top: 500px;
  }

  .visionTitle p {
    margin: 57px 0px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
  }
  .mission-wrap h1 {
    position: absolute;
    width: 200px;
    height: 38px;
    left: 0px;
    top: 720px;
    margin: 0 107px;
    color: white;
    text-align: center;
  }

  .missionTitle {
    border-radius: 15px;
    margin: 0px 25px;
    width: 364px;
    height: 293px;
    top: 780px;
  }

  .missionTitle p {
    margin: 30px 0px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
  }
  .ourteam-wrapper {
    height: 430px;
  }
  .ourteam-wrapper h1 {
    width: 100%;
    margin: 0px;
  }

  .ourteam-wrapper li {
    font-size: 16px;
    padding: 0 10px;
  }

  .ourteam-wrapper ul {
    padding: 0px;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  .about {
    height: 1030px;
    width: 390px;
  }
  .bg-image {
    height: 200px;
    width: 390px;
    object-fit: cover;
  }

  .logo-image {
    width: 200px;
    height: 107px;
  }

  .bg-img-title {
    top: 0px;
    margin: 0 184px;
    justify-content: center;
    margin: 20px 95px;
  }

  .bg-img-title h1 {
    margin: 0px 0px;
    font-size: 20px;
    text-align: center;
  }

  .vision-wrap {
    width: 390px;
  }

  .vision-wrap h1 {
    position: absolute;
    width: 200px;
    height: 38px;
    left: 95px;
    top: 370px;
  }

  .visionTitle {
    border-radius: 170px 170px 170px 170px;
    width: 370px;
    height: 200px;
    left: 0px;
    top: 450px;
    margin: 10px;
  }

  .visionTitle p {
    margin: 45px 35px;
    height: 100px;
    width: 300px;
  }

  .mission-wrap h1 {
    position: absolute;
    width: 200px;
    height: 38px;
    left: 95px;
    top: 670px;
  }

  .missionTitle {
    border-radius: 130px 130px 130px 130px;
    width: 370px;
    height: 400px;
    margin: 0px 10px;
    top: 760px;
  }

  .missionTitle p {
    width: 300px;
    margin-left: 0;
    margin: 50px 35px;
  }
}
