.header-srv {
  position: relative;
}

.bg-header {
  opacity: 0.6;
  background-size: cover;
  width: 100%;
}

.header-title-srv {
  position: absolute;
  display: flex;
  width: 100%;
  height: 300px;
  align-items: center;
  justify-content: center;
  top: 0px;
  margin: 0px;
  color: white;
  text-shadow: rgb(93, 92, 88) 1px 0 10px;
}

.content-srv img {
  height: 230px;
  width: 230px;
  border-radius: 25px;
  border: 1px solid grey;
}

.contents-srv {
  height: 1119px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
  text-align: center;
  padding: 123px 0px;
}

.content-srv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (min-width: 391px) and (max-width: 768px) {
  .header-title-srv {
    width: 100%;
    height: 200px;
  }

  .bg-header {
    height: 200px;
  }

  .contents-srv {
    padding: 0px 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    height: 1600px;
  }

  .content-srv {
    font-size: 16px;
  }

  .content-srv img {
    height: 180px;
    width: 180px;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  .header-title-srv {
    width: 390px;
    height: 150px;
  }

  .bg-header {
    height: 150px;
    width: 390px;
    object-fit: cover;
  }

  .contents-srv {
    padding: 0px 0;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0px;
    column-gap: 0px;
    height: 1600px;
  }

  .content-srv {
    font-size: 16px;
  }

  .content-srv img {
    height: 150px;
    width: 150px;
  }
}
