.contact-us {
  background-color: #f4fbff;
}
.top-right-cu img {
  width: 866px;
  height: 411px;
}
.top-left-cu {
  margin-top: 60px;
  margin-left: 80px;
}
.top-left-cu h2 {
  border-bottom: 5px rgb(255, 255, 255) solid;
  width: 150px;
}

.top-cu {
  height: 411px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #61a5c2;
  color: white;
}

.socmed-cu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.socmed-cu a {
  font-size: 16px;
  margin: 5px 0;
  color: white;
}
.socmed-card-cu {
  display: flex;
  width: 204px;
  justify-content: space-between;
}

.bottom-cu {
  height: 800px;
}

.address-contents {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 30px;
  gap: 42px;
  margin-top: 101px;
}

.address-card {
  position: relative;
  width: 566px;
  height: 175px;
  background-color: white;
  border: 2px grey solid;
  border-radius: 35px;
  margin: 0 auto;
}

.address-card a {
  text-decoration: none;
  color: blue;
}

.address-card h1 {
  font-size: 20px;
  margin-left: 52px;
  margin-top: 45px;
  margin-bottom: 0;
}

.address-card p {
  color: grey;
  margin: 0px;
  margin-left: 52px;
  font-size: 20px;
}

.address-card button {
  width: 100px;
  margin-left: 321px;
  color: white;
  background-color: #61a5c2;
  width: 192px;
  height: 36px;
  border: none;
  border-radius: 44px;
  font-size: 17px;
  cursor: pointer;
}

@media screen and (min-width: 391px) and (max-width: 768px) {
  .top-cu {
    height: 200px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .top-right-cu img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .top-left-cu {
    margin: 10px 10px;
  }

  .top-left-cu h1 {
    font-size: 20px;
  }

  .bottom-cu {
    height: max-content;
  }

  .address-contents {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin: 10px 30px;
  }

  .address-card {
    width: 270px;
    height: 150px;
  }

  .address-card h1 {
    height: 43px;
    font-size: 16px;
    text-align: center;
    margin-left: 0px;
    margin-top: 20px;
  }

  .address-card p {
    margin-left: 0px;
    font-size: 16px;
    text-align: center;
  }

  .address-card button {
    width: 80px;
    height: 36px;
    margin: 10px 90px;
    color: white;
    background-color: #61a5c2;
    border: none;
    border-radius: 44px;
    font-size: 10px;
    font-weight: bold;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  .top-cu {
    height: 250px;
    width: 390px;
  }

  .top-right-cu img {
    width: 390px;
    height: 300px;
    object-fit: cover;
    position: absolute;
  }

  .top-left-cu {
    position: relative;
    margin: auto auto;
  }

  .bottom-cu {
    height: 840px;
  }

  .address-contents {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin: 30px 30px;
  }

  .address-card {
    width: 270px;
    height: 150px;
  }

  .address-card h1 {
    height: 43px;
    font-size: 16px;
    text-align: center;
    margin-left: 0px;
    margin-top: 20px;
  }

  .address-card p {
    margin-left: 0px;
    font-size: 16px;
    text-align: center;
  }

  .address-card button {
    width: 80px;
    height: 36px;
    margin: 10px 90px;
    color: white;
    background-color: #61a5c2;
    border: none;
    border-radius: 44px;
    font-size: 10px;
    font-weight: bold;
  }
}
