.homepage {
  height: 100%;
  background-color: #efeeee;
}

.top-homepage {
  height: 769px;
  background-color: #e1eff8;
  display: flex;
  flex-direction: row;
}

.greeting {
  position: absolute;
  width: 400px;
  height: 222px;
  left: 793px;
  top: 350px;
}

.greeting p {
  text-align: justify;
  font-size: 14px;
  margin: 0;
}

.greeting h1,
h2 {
  margin: 0;
  text-align: left;
}

.greeting h1 {
  color: #1680e5;
  height: 40px;
}

.greeting button {
  margin-left: 290px;
  margin-top: 10px;
  width: 110px;
  height: 33px;
  border-radius: 30px;
  background-color: #1680e5;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border: none;
  cursor: pointer;
}

.greeting button:hover {
  background-color: #fff;
  color: #1680e5;
}

.greeting button:active {
  position: relative;
  top: 5px;
}

.btn-greeting-link {
  color: white;
  text-decoration: none;
}

@media screen and (min-width: 391px) and (max-width: 768px) {
  .homepage {
    height: 680px;
  }

  .homePict img {
    width: 100%;
  }

  .greeting {
    position: absolute;
    width: 100%;
    height: 300px;
    left: 0px;
    top: 570px;
    background-color: #ffffff;
  }

  .greeting-title {
    margin: 30px 50px;
  }

  .greeting button {
    margin-left: 50px;
    margin-top: 0;
  }
  .home-pict {
    width: 768px;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  .homepage {
    height: 650px;
  }

  .greeting {
    position: absolute;
    width: 390px;
    height: 330px;
    left: 0px;
    top: 490px;
    background-color: #ffffff;
  }

  .greeting-title {
    margin: 30px 50px;
  }

  .greeting button {
    margin-left: 50px;
    margin-top: 0;
  }
  .home-pict {
    width: 390px;
  }
}
