.container-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar {
  height: 90px;
  display: flex;
  flex-direction: row;
  margin: 0 80px;
}

.header-left {
  float: left;
  margin: 3px;
}

.header-logo {
  width: 90px;
}

.header-right ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  color: #1680e5;
  font-weight: bold;
  margin: 0 0 0 0px;
  align-items: center;
  justify-content: flex-end;
  height: 90px;
}

.header-right li {
  margin-left: 79px;
  cursor: pointer;
}

.header-right li:hover {
  color: #64b3fd;
}

.header-right {
  color: #1680e5;
  text-decoration: none;
}

@media screen and (min-width: 391px) and (max-width: 768px) {
  .navbar {
    width: 100%;
    height: 180px;
    margin: 0px;
    flex-direction: none;
  }

  .container-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: none;
    width: 100%;
  }
  .header-right li {
    width: 300px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    margin: 3px 0;
  }

  .header-right ul {
    justify-content: space-around;
    font-size: 7px;
    flex-direction: column;
    margin-left: 0px;
    padding: 0px;
    font-size: 14px;
  }

  .header-left {
    margin: 0px;
    float: none;
  }
  .header-logo {
    width: 70px;
    margin-left: 0px;
  }
}

@media screen and (min-width: 200px) and (max-width: 390px) {
  .navbar {
    width: 390px;
    height: 170px;
    margin: 0px;
  }
  .container-nav {
    display: flex;
    flex-direction: column;
    width: 390px;
    justify-content: center;
    align-items: center;
  }
  .header-right li {
    width: 300px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    margin: 3px 0;
  }

  .header-right ul {
    justify-content: space-around;
    font-size: 7px;
    flex-direction: column;
    margin-left: 0px;
    padding: 0px;
    font-size: 14px;
  }

  .header-left {
    margin: 0px;
    float: none;
  }
  .header-logo {
    width: 70px;
    margin-left: 0px;
  }
}
